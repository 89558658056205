<template>
  <!-- BEGIN: Content-->
  <div class="app-content content font">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row"></div>
      <div class="content-body">
        <!-- Dashboard Ecommerce Starts -->
        <section id="dashboard-ecommerce ">
          <div class="row match-height" style="margin: 5px 5px 5px 5px">
            <!-- Medal Card -->
            <!-- <div class="col-xl-4 col-md-4 col-12" style="padding: 5px 5px 5px 5px">
              <div class="card custom-card card-congratulation-medal">
                <div class="card-body font">
                  <h5 style="color: #00364f">
                    <strong>WELCOME </strong> <br />
                     <br />
                  </h5>
            
                  <h1 class="mb-75 mt-2 pt-50 font">
                    
                <strong style="color: #f21300"> <span v-if="userInfo.gender == 'male'">Mr. </span> <span v-else>Mrs. </span> {{ userInfo.name }}</strong>
                  </h1>

                  <img
                    src="/vimal-assets/app-assets/images/illustration/badge.svg"
                    class="congratulation-medal"
                    alt="Medal Pic"
                  />
                </div>
              </div>
            </div> -->
            <!--/ Medal Card -->

            <!-- Statistics Card -->


            <div class="col-xl-12 col-md-12 col-12" style="padding: 5px 5px 5px 5px">
              <div class="d-block d-md-none pt-3"></div>
              <div class="card custom-card card-company-table mt-top-cards">
                <div class="table-responsive">
                  <table class="table table-bordered-none">
                    <tbody>
                      <tr class="text-truncate">
                        <td>
                          <div class="row">
                            <div
                              class="col-xl-6 col-md-6 col-12 d-block d-xl-none d-md-none"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.admin
                              }}</strong>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12">
                              <strong class="text">Admin</strong>
                            </div>
                            <div
                              class="col-xl-6 col-md-6 col-12 text-end d-none d-xl-block d-md-block"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.admin
                              }}</strong>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div class="row">
                            <div
                              class="col-xl-6 col-md-6 col-12 d-block d-xl-none d-md-none"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.masterDistributer
                              }}</strong>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12">
                              <strong class="text"> Master Distributer</strong>
                            </div>
                            <div
                              class="col-xl-6 col-md-6 col-12 text-end d-none d-xl-block d-md-block"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.masterDistributer
                              }}</strong>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="row">
                            <div
                              class="col-xl-6 col-md-6 col-12 d-block d-xl-none d-md-none"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.staff
                              }}</strong>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12">
                              <strong class="text"> Staff</strong>
                            </div>
                            <div
                              class="col-xl-6 col-md-6 col-12 text-end d-none d-xl-block d-md-block"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.staff
                              }}</strong>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div class="row">
                            <div
                              class="col-xl-6 col-md-6 col-12 d-block d-xl-none d-md-none"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.distributer
                              }}</strong>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12">
                              <strong class="text"> Distributer</strong>
                            </div>
                            <div
                              class="col-xl-6 col-md-6 col-12 text-end d-none d-xl-block d-md-block"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.distributer
                              }}</strong>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="row">
                            <div
                              class="col-xl-6 col-md-6 col-12 d-block d-xl-none d-md-none"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.professional
                              }}</strong>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12">
                              <strong class="text"> Professional</strong>
                            </div>
                            <div
                              class="col-xl-6 col-md-6 col-12 text-end d-none d-xl-block d-md-block"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.professional
                              }}</strong>
                            </div>
                          </div>
                        </td>

                        <td>
                          <div class="row">
                            <div
                              class="col-xl-6 col-md-6 col-12 d-block d-xl-none d-md-none"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.retailer
                              }}</strong>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12">
                              <strong class="text"> Retailer</strong>
                            </div>
                            <div
                              class="col-xl-6 col-md-6 col-12 text-end d-none d-xl-block d-md-block"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.retailer
                              }}</strong>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div class="row">
                            <div
                              class="col-xl-6 col-md-6 col-12 d-block d-xl-none d-md-none"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.client
                              }}</strong>
                            </div>
                            <div class="col-xl-6 col-md-6 col-12">
                              <strong class="text"> Clients</strong>
                            </div>
                            <div
                              class="col-xl-6 col-md-6 col-12 text-end d-none d-xl-block d-md-block"
                            >
                              <strong style="color: #f21000">{{
                                dashboard.client
                              }}</strong>
                            </div>
                          </div>
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!--/ Statistics Card -->
          </div>

          <div class="row" style="margin: -32px 5px 5px 5px">
            <div
              class="col-md-3 col-xl-3 col-lg-3 col-6"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card custom-card">
                <div class="card-body"  style="padding: 0.5rem 0.5rem 0.5rem 1rem">
                  <div class="d-flex">
                    <div class="font-heading" style="font-size: 3rem">
                      <font-awesome-icon icon="edit" />
                    </div>
                    &nbsp;&nbsp;
                    <div class="row">
                      <div class="col-12">
                        <h1>
                          <strong style="color: #f21000">{{
                            dashboard.clientServiceTotal
                          }}</strong>
                        </h1>
                      </div>
                      <div class="col-12">
                        <strong class="text">Projects</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-xl-3 col-lg-3 col-6"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card custom-card">
                <div class="card-body"  style="padding: 0.5rem 0.5rem 0.5rem 1rem">
                  <div class="d-flex">
                    <div class="font-heading" style="font-size: 3rem">
                      <font-awesome-icon icon="clock" />
                    </div>
                    &nbsp;&nbsp;
                    <div class="row">
                      <div class="col-12">
                        <h1>
                          <strong style="color: #f21000"
                            ><strong style="color: #f21000">{{
                              dashboard.clientServiceAssigned +
                              dashboard.clientServiceProcessed +
                              dashboard.clientServiceReject +
                              dashboard.clientServicePending +
                              dashboard.clientServiceFinalize +
                              dashboard.clientServiceHold +
                              dashboard.clientServiceUpgrade
                            }}</strong></strong
                          >
                        </h1>
                      </div>
                      <div class="col-12">
                        <strong class="text">In Process</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
           
            <div
              class="col-md-3 col-xl-3 col-lg-3 col-6"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card custom-card mt-top-numbers">
                <div class="card-body"  style="padding: 0.5rem 0.5rem 0.5rem 1rem">
                  <div class="d-flex">
                    <div class="font-heading" style="font-size: 3rem">
                      <font-awesome-icon icon="exclamation-circle" />
                    </div>
                    &nbsp;&nbsp;
                    <div class="row">
                      <div class="col-12">
                        <h1>
                          <strong style="color: #f21000">
                            <strong style="color: #f21000">{{
                              dashboard.clientServiceCancelled
                            }}</strong></strong
                          >
                        </h1>
                      </div>
                      <div class="col-12">
                        <strong class="text">Cancelled</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="col-md-3 col-xl-3 col-lg-3 col-6"
              style="padding: 5px 5px 5px 5px"
            >
              <div class="card custom-card mt-top-numbers">
                <div class="card-body"  style="padding: 0.5rem 0.5rem 0.5rem 1rem">
                  <div class="d-flex">
                    <div class="font-heading" style="font-size: 3rem">
                      <font-awesome-icon icon="check-double" />
                    </div>
                    &nbsp;&nbsp;
                    <div class="row">
                      <div class="col-12">
                        <h1>
                          <strong style="color: #f21000">{{
                            dashboard.clientServiceCompleted
                          }}</strong>
                        </h1>
                      </div>
                      <div class="col-12">
                        <strong class="text">Completed</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-block d-md-none pt-1">
          
         <br>
            </div>
        
          </div>

          <div class="row match-height" style="margin: -32px 5px 5px 5px">
            <div class="col-lg-4 col-12" style="padding: 5px 5px 5px 5px" v-if="permissionCheck.upgradeView == true">
              <div class="card custom-card card-transaction mt-top-cards" >
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 text-truncate">
                      <h4><strong>Upgrades</strong></h4>
                    </div>
                    <div class="col-4 text-end">
                      <router-link
                        to="/staff/upgrades"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </router-link>
                    </div>
                  </div>

                  <hr />
                  <div
                    class="transaction-item"
                    style="
                      border-bottom: 1px solid #ededed;
                      padding-bottom: 5px;
                      margin-top: -10px;
                    "
                    v-for="(upgrade, index) in upgrades"
                    :key="index"
                  >
                    <div class="d-flex">
                      <div class="avatar rounded float-start" style="background: white">
                        <strong style="color: #00364f"> Rs.{{ upgrade.amt }} </strong>
                      </div>
                      <div class="transaction-percentage">
                        <h6 class="transaction-title">
                          <strong style="color: #00364f">{{ upgrade.title }}</strong>
                        </h6>
                        <small
                          ><span v-if="upgrade.client_service">{{
                            upgrade.client_service.clientServiceNo
                          }}</span>
                          ( {{ dateTime(upgrade.date) }})</small
                        >
                      </div>
                    </div>
                 
                    <div v-if="upgrade.status == 'pending'" class="text-danger">
                      <p
                        class="btn btn-sm"
                        style="
                          width: 80px;
                          background-color: #808080;
                          color: white;
                          box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                          border-radius: 50px;
                          font-size: 10px;
                          padding: 3px 3px 3px 3px;
                        "
                      >
                        <b>{{ upgrade.status }}</b>
                      </p>
                    </div>
                    <div v-if="upgrade.status == 'cancel'" class="text-danger">
                      <p
                        class="btn btn-sm"
                        style="
                          width: 80px;
                          background-color: #f21000;
                          color: white;
                          box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                          border-radius: 50px;
                          font-size: 10px;
                          padding: 3px 3px 3px 3px;
                        "
                      >
                        <b>{{ upgrade.status }}</b>
                      </p>
                    </div>
                    <div v-if="upgrade.status == 'approved'" class="text-danger">
                      <p
                        class="btn btn-sm"
                        style="
                          width: 80px;
                          background-color: green;
                          color: white;
                          box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                          border-radius: 50px;
                          font-size: 10px;
                          padding: 3px 3px 3px 3px;
                        "
                      >
                        <b>{{ upgrade.status }}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Plateform Admins Card -->
            <div class="col-lg-4 col-12" style="padding: 5px 5px 5px 5px"  v-if="permissionCheck.adminView == true">
              <div class="card custom-card card-transaction mt-top-cards" >
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 text-truncate">
                      <h4><strong>Plateform Admins</strong></h4>
                    </div>
                    <div class="col-4 text-end">
                      <span  class="btn btn-sm my-btn"  @click="storPortal('true')">
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </span>
                      <!-- <router-link
                      @click="storPortal('true')"
                        to="#"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </router-link> -->
                    </div>
                  </div>

                  <hr />
                  <div
                    class="transaction-item"
                    style="
                      border-bottom: 1px solid #ededed;
                      padding-bottom: 5px;
                      margin-top: -10px;
                    "
                    v-for="(plateAdm, index) in plateformAdmins"
                    :key="index"
                  >
                    <div class="d-flex">
                      <div class="avatar rounded float-start" style="background: white">
                        <div class="avatar-content">
                          <router-link
                            :to="`/staff/admin-profile-view/${plateAdm.id}`"
                            type="button"
                          >
                            <img
                              style="width: 35px; height: 35px"
                              :src="adminlogofinder(plateAdm)"
                              alt=""
                            />
                          </router-link>
                        </div>
                      </div>
                      <div class="transaction-percentage">
                        <h6 class="transaction-title">
                          <strong style="color: #00364f">{{
                            plateAdm.orgnization
                          }}</strong>
                        </h6>
                        <small>{{ plateAdm.name }}</small>
                      </div>
                    </div>
                    <div v-if="plateAdm.loginStatus == 'true'" class="text-success">
                      Active
                    </div>
                    <div v-else class="text-danger">InActive</div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Plateform Admins Card -->

            <!-- Plateform Admins Card -->
            <div class="col-lg-4 col-12" style="padding: 5px 5px 5px 5px" v-if="permissionCheck.adminView == true">
              <div class="card custom-card card-transaction mt-top-cards" >
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 text-truncate">
                      <h4><strong>Franchise Admins</strong></h4>
                    </div>
                    <div class="col-4 text-end">
                      <span  class="btn btn-sm my-btn"  @click="storPortal('false')">
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </span>
                      <!-- <router-link
                      @click="storPortal('false')"
                        to="#"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </router-link> -->
                    </div>
                  </div>

                  <hr />
                  <div
                    class="transaction-item"
                    style="
                      border-bottom: 1px solid #ededed;
                      padding-bottom: 5px;
                      margin-top: -10px;
                    "
                    v-for="(frenchAdm, index) in frenchformAdmins"
                    :key="index"
                  >
                    <div class="d-flex">
                      <div class="avatar rounded float-start" style="background: white">
                        <div class="avatar-content">
                          <router-link
                            :to="`/staff/admin-profile-view/${frenchAdm.id}`"
                            type="button"
                          >
                            <img
                              style="width: 35px; height: 35px"
                              :src="adminlogofinder(frenchAdm)"
                              alt=""
                            />
                          </router-link>
                        </div>
                      </div>
                      <div class="transaction-percentage">
                        <h6 class="transaction-title">
                          <strong style="color: #00364f">{{
                            frenchAdm.orgnization
                          }}</strong>
                        </h6>
                        <small>{{ frenchAdm.name }}</small>
                      </div>
                    </div>
                    <div v-if="frenchAdm.loginStatus == 'true'" class="text-success">
                      Active
                    </div>
                    <div v-else class="text-danger">InActive</div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Plateform Admins Card -->
          </div>

          <div class="row match-height" :style="(fixmarginAdmin() == 'true') ? `margin: -32px 5px 5px 5px` : `margin: -15px 5px 5px 5px`">
            <!-- Company Table Card -->
            <div class="col-lg-8 col-12"  v-if="permissionCheck.challanView == true" style="padding: 5px 5px 5px 5px">
              <div class="card custom-card card-company-table">
                <div class="card-body p-0">
                  <div class="row pt-2 p-1">
                    <div class="col-8 text-truncate">
                      <h4><strong>Challans</strong></h4>
                    </div>
                    <div class="col-4 text-end">
                      <router-link
                        to="/staff/challan"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </router-link>
                    </div>
                  </div>

                  <div
                    class="container-fluid table-scroll"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <table class="table table-hover table-sm" style="color: #00364f">
                      <tr
                        class="text tr-head table-wrapper-scroll-y rounded-circle"
                        style="background-color: white; color: #0634f"
                      >
                        <th
                          class="text-truncate"
                          style="
                            background-color: #cdcdcd;
                            border-radius: 8px 0px 0px 8px;
                          "
                        >
                          Project ID
                        </th>
                        <th class="text-truncate" style="background-color: #cdcdcd">
                          Date
                        </th>
                        <th class="text-truncate" style="background-color: #cdcdcd">
                          Challan No.
                        </th>

                        <th class="text-truncate" style="background-color: #cdcdcd">
                          Challan
                        </th>
                        <th class="text-truncate" style="background-color: #cdcdcd">
                          Amount
                        </th>
                        <th class="text-truncate" style="background-color: #cdcdcd">
                          Status
                        </th>
                        <th
                          style="
                            background-color: #cdcdcd;
                            border-radius: 0px 8px 8px 0px;
                          "
                          class="text-truncate"
                        >
                          Action
                        </th>
                      </tr>
                      <tbody class="text">
                        <tr v-for="(challan, index) in challans" :key="index">
                          <td class="text-truncate" v-if="challan.client_service">
                            {{ challan.client_service.clientServiceNo }}
                          </td>

                          <td class="text-truncate" v-if="challan">
                            {{ challan.date }}
                          </td>
                          <td class="text-truncate" v-if="challan">
                            {{ challan.challanNo }}
                          </td>
                          <td class="text-truncate" v-if="challan">
                            <span> {{ challan.title }} </span>
                          </td>

                          <td class="text-truncate" v-if="challan">
                            <span v-if="challan.amt"> {{ challan.amt }} </span>
                          </td>
                          <td class="text-truncate" v-if="challan">
                            <p
                              v-if="challan.status == 'pending'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: gray;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                                margin-top: 10px;
                              "
                            >
                              <b>pending</b>
                            </p>
                            <p
                              v-if="challan.status == 'approve'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: green;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                                margin-top: 10px;
                              "
                            >
                              <b>{{ challan.status }}</b>
                            </p>

                            <p
                              v-if="challan.status == 'reject'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: black;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                                margin-top: 10px;
                              "
                            >
                              <b>{{ challan.status }}</b>
                            </p>
                          </td>
                          <td class="text-truncate text-end">
                            <div
                              class="btn-group btn-group-sm"
                              role="group"
                              aria-label="..."
                            >
                              <router-link
                                :to="`/staff/project-detailes/${challan.client_service_id}`"
                                class="btn btns btn-sm text-white"
                                style="
                                  background-color: #00364f;
                                  padding: 5px 4.5px 5px 4.5px;
                                "
                              >
                                <font-awesome-icon icon="eye" />
                              </router-link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Company Table Card -->

            <!-- Plateform Admins Card -->
            <div class="col-lg-4 col-12" style="padding: 5px 5px 5px 5px" v-if="permissionCheck.ticket == true">
              <div class="card custom-card card-transaction mt-top-cards">
                <div class="card-body">
                  <div class="row">
                    <div class="col-8 text-truncate">
                      <h4><strong>Tickets</strong></h4>
                    </div>
                    <div class="col-4 text-end">
                      <router-link
                        to="/staff/ticket"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </router-link>
                    </div>
                  </div>

                  <hr />
                  <div
                    class="transaction-item"
                    v-for="(ticket, index) in tickets"
                    :key="index"
                  >
                    <div class="d-flex">
                      <div class="transaction-percentage">
                        <h6 class="transaction-title">
                          <strong style="color: #00364f" v-if="ticket.department">{{
                         ticket.department.name
                          }}</strong>
                        </h6>
                        <small
                          >{{ ticket.ticketNo }} ({{
                            dateTime(ticket.updated_at)
                          }}
                          )</small
                        >
                      </div>
                    </div>
                    <div>
                      <p
                        v-if="ticket.status == 'open'"
                        class="btn btn-sm"
                        data-v-4a972433=""
                        style="
                          width: 60px;
                          background-color: green;
                          color: white;
                          box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px 0px;
                          border-radius: 50px;
                          font-size: 10px;
                          padding: 3px;
                          margin-top: 10px;
                        "
                      >
                        <b data-v-4a972433="">open</b>
                      </p>
                      <p
                        v-else
                        class="btn btn-sm"
                        data-v-4a972433=""
                        style="
                          width: 60px;
                          background-color: #f21000;
                          color: white;
                          box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px 0px;
                          border-radius: 50px;
                          font-size: 10px;
                          padding: 3px;
                          margin-top: 10px;
                        "
                      >
                        <b data-v-4a972433="">close</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Plateform Admins Card -->
          </div>

          <!-- start new -->

          <div class="row match-height" style="margin: -32px 5px 5px 5px" v-if="permissionCheck.verifications == true">
            <!-- Company Table Card -->
            <div class="col-lg-12 col-12" style="padding: 5px 5px 5px 5px" >
              <div class="card custom-card card-company-table">
                <div class="card-body p-0">
                  <div class="row pt-2 p-1">
                    <div class="col-8 text-truncate">
                      <h4><strong>Verifications</strong></h4>
                    </div>
                    <div class="col-4 text-end">
                      <router-link
                        to="/staff/veryfing-professional-projects"
                        type="button"
                        class="btn btn-sm my-btn"
                      >
                        <small style="color: black"> View All &nbsp;&nbsp;</small>
                        <img
                          style="width: 10px"
                          src="/assets/image/right-arrow.png"
                          alt=""
                        />
                      </router-link>
                    </div>
                  </div>

                  <div
                    class="container-fluid table-scroll"
                    style="padding: 5px 5px 5px 5px"
                  >
                    <table class="table table-hover table-sm text font">
                      <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                        <th class="text-truncate">Code</th>
                        <th class="text-truncate">Date</th>
                        <th class="text-truncate">Name</th>
                        <th class="text-truncate">Email</th>
                        <th class="text-truncate">Service</th>
                        <!-- <th class="text-truncate">Progress</th> -->
                        <th class="text-truncate">Status</th>
                      </tr>
                      <tbody class="text-truncate">
                        <tr v-for="(verify, index) in verifications" :key="index">
                          <td>
                            <span v-if="verify.client_service">
                              {{ verify.client_service.clientServiceNo }}</span
                            >
                          </td>
                          <td>
                            <span v-if="verify">
                              {{ dateTime(verify.date) }}
                            </span>
                          </td>
                          <td>
                            <span v-if="verify.client_service">
                              <span v-if="verify.client_service.client">
                                {{ verify.client_service.client.fname }}
                                {{ verify.client_service.client.lname }}
                              </span>
                            </span>
                          </td>

                          <td class="text-truncate">
                            <span v-if="verify.client_service">
                              <span v-if="verify.client_service.client">
                                {{ verify.client_service.client.email }}
                              </span>
                            </span>
                          </td>
                          <td class="text-truncate">{{ verify.service.name }}</td>
                          <!-- <td class="text-truncate">
                              <div class="progress" style="height: 7px">
                                <div
                                  style="background-color: #f21300; width: 50%"
                                  class="progress-bar"
                                  role="progressbar"
                                  aria-valuenow="40"
                                  aria-valuemin="40"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </td> -->
                          <td class="text-truncate">
                            <p
                              v-if="verify.status == 'pending'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: gray;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                              "
                            >
                              <b>New</b>
                            </p>
                            <p
                              v-if="verify.status == 'assigned'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: #349c9e;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                              "
                            >
                              <b>{{ verify.status }}</b>
                            </p>
                            <p
                              v-if="verify.status == 'completed'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: #3ab04b;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                              "
                            >
                              <b>{{ verify.status }}</b>
                            </p>
                            <p
                              v-if="verify.status == 'finalize'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: #ffaa00;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                              "
                            >
                              <b>{{ verify.status }}</b>
                            </p>

                            <p
                              v-if="verify.status == 'hold'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: #8f8d8e;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                              "
                            >
                              <b>{{ verify.status }}</b>
                            </p>

                            <p
                              v-if="verify.status == 'rejected'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: black;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                              "
                            >
                              <b>{{ verify.status }}</b>
                            </p>

                            <p
                              v-if="verify.status == 'cancelled'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: #f50303;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                              "
                            >
                              <b>{{ verify.status }}</b>
                            </p>
                            <p
                              v-if="verify.status == 'upgrade'"
                              class="btn btn-sm"
                              style="
                                width: 80px;
                                background-color: #ee0def;
                                color: white;
                                box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
                                border-radius: 50px;
                                font-size: 10px;
                                padding: 3px 3px 3px 3px;
                              "
                            >
                              <b>{{ verify.status }}</b>
                            </p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!--/ Company Table Card -->
          </div>
        </section>
        <!-- Dashboard Ecommerce ends -->
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>

import moment from "moment";
export default {
 
  name: "SuperAdminHome",
  
  data() {
    return {




      userInfo: {},
      challans: [],
      upgrades: [],
      verifications: [],
      usersCount: {},
      dashboard: {},
      plateformAdmins: [],
      frenchformAdmins: [],
      tickets: [],
      withdrawls: [],
      departMents: [],
      permissionCheck: {
        adminView: true,
        upgradeView: true,
        challanView: true,
        verifications: true,
        ticket: true,
        kycCreate: "",
        kycUpdate: "",
        kycApprove: "",
      },
    };
  },

  methods: {
    fixmarginAdmin(){
      if(this.permissionCheck.adminView == true || this.permissionCheck.upgradeView == true){
        return 'true'
      }else{
        return 'false'
      }
    },
    loadDashboard() {
      this.loading = true;
      this.$axios
        .get(`staff/dashboard`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.dashboard = res.data;
        })
        .finally(() => (this.loading = false));
    },
    loadDepartments() {
      this.loading = true;
      this.$axios
        .get(`staff/department?all=all`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.departMents = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadChallans() {
      this.loading = true;
      this.$axios
        .get(`staff/clientservicechallan?per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.challans = res.data.data.data;
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          if( error.response.data.error == 'Sorry! You do not have permission to access.'){
            this.permissionCheck.challanView = false
          }
           
          });
    },
    dateTime(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    loadWallet() {
      this.loading = true;
      this.$axios
        .post(
          `staff/user/count`,
          {},
          {
            headers: { Authorization: "Bearer " + localStorage.accessToken },
          }
        )
        .then((res) => {
          console.log(res);
          this.usersCount = res.data;
        })
        .finally(() => (this.loading = false));
    },
    adminlogofinder(admin) {
      var photo = "";
      if (admin.logo != null) {
        photo = this.$store.state.imgUrl + "/admin/" + admin.id + "/thumbs/" + admin.logo;
        return photo;
      } else {
        photo = this.$store.state.placeholderImg;
        return photo;
      }
    },
    loadTickets() {
      this.loading = true;
      this.$axios
        .get(`staff/clientserviceticket?per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.tickets = res.data.data.data;
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          if( error.response.data.error == 'Sorry! You do not have permission to access.'){
            this.permissionCheck.ticket = false
          }
            
          });
    },
    loadUpgrades() {
      this.loading = true;
      this.$axios
        .get(`staff/clientserviceupgrade?per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.upgrades = res.data.data.data;
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          if( error.response.data.error == 'Sorry! You do not have permission to access.'){
            this.permissionCheck.upgradeView = false
          }
           
          });
    },
    loadVerifications() {
      this.loading = true;
      this.$axios
        .get(`staff/clientserviceprofessional?status=finalize&per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.verifications = res.data.data.data;
        })
        .finally(() => (this.loading = false))
        .catch((error) => {
          if( error.response.data.error == 'Sorry! You do not have permission to access.'){
            this.permissionCheck.verifications = false
          }
            
          });
    },
   
    loadAdmins() {
      this.loading = true;
      this.$axios
        .get(`staff/admin?portal=true&per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.plateformAdmins = res.data.data.data;
        })
        .catch((error) => {
          if( error.response.data.error == 'Sorry! You do not have permission to access.'){
            this.permissionCheck.adminView = false
          }
            
          });

      this.$axios
        .get(`staff/admin?portal=false&per_page=5`, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.frenchformAdmins = res.data.data.data;
        })

        .finally(() => (this.loading = false));
    },
    storPortal(portal){

      localStorage.setItem("portal", portal);
      this.$router.push("/staff/admins-staff");
    },
    loadPageTitle(){
        document.title =this.$store.state.staffPageTitles.dashboard
      }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.loadAdmins();
      this.loadTickets();
      this.loadVerifications();
      this.loadUpgrades();
      this.loadWallet();
      this.loadChallans();
      this.loadDepartments();
      this.loadDashboard();
      this.userInfo = JSON.parse(localStorage.getItem("userData"));
      if(localStorage.getItem("winLoad") == 'true'){
        localStorage.setItem("winLoad", 'false');
        window.location.reload();
      }
      this.loadPageTitle()
      
    }
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");
.font {
  font-family: "Poppins", sans-serif;
}

.bg-1-project {
}

/* .bg-1-admin {
  background: rgb(255, 255, 255);
  background: radial-gradient(circle, rgba(255, 255, 255, 0) 0%, rgba(0, 54, 79, 1) 100%);
} */

/* .bg-2-admin {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 0) 0%,
    rgba(242, 16, 0, 1) 100%
  );
} */

.bg-light-success {
  background: white !important;
}
.my-btn {
  color: black;
  transition: 0.5s;
  margin-right: -20px;
}
.my-btn:hover {
  background: rgba(212, 206, 206, 0.438);
}

.table-scroll {
  overflow-y: auto;
}

.form-select {
  border: 1px solid #00364f;
}

.form-checkbox {
  width: 25px;
  margin-top: 5px;
}

nav ul {
  height: 300px;
  width: 100%;
}

nav ul {
  overflow: hidden;
  overflow-y: scroll;
}

div.scrollmenu {
  overflow: auto;
  white-space: nowrap;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  font-family: "Poppins", sans-serif;
  color: #00364f;
  text-decoration: none;
}

div.scrollmenu a .card {
  height: 50%;
  width: 40%;
}

.come-from-right .modal-dialog {
  position: fixed;
  top: 0px;
  right: 0px;
  margin: auto;
  width: 300px;
  height: 100%;
  transform: translate3d(0%, 0, 0);
  transition: opacity 0.3s linear, left 0.3s ease-out;
}

.come-from-right .modal-content {
  height: 100%;
  overflow-y: auto;
  border-radius: 0px;
}

.come-from-right .modal-body {
  padding: 15px 15px 80px;
}
.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}

@media screen and (max-width: 767px) {
  .mt-top-numbers {
    margin-top: -26px;
  }
  .mt-top-cards {
    margin-top: -27px;
  }
}

.h1-heading {
  color: red;
  font-size: 3vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 2rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.btns {
  display: inline-flex;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}
.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}
h4[data-v-69e5a8da] {
  color: #fff;
  font-family: poppins, Sans-serif;
  font-weight: 600;
  font-size: 25;
}
input:checked {
  /* background-color: #7367f0; */
  border-color: #ff000c;
  border-width: 3px;
}
.form-check-input:checked {
  background-color: #fdfdfd;
  /* border-color: #7367f0; */
}
</style>
